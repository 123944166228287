<template>
    <div style="container-type:inline-size;">
      <div
        class="tw-flex tw-items-center c-page-footer__container-justified c-page-footer__container-justified c_footer-container">
        <div class="logo tw-relative">
          <div>
            <a href="/" aria-current="page" class="nuxt-link-exact-active nuxt-link-active">
              <div class="logo tw-relative">
                <figure class="logo__container"><img src="@/assets/logo.png" alt="My BrandSite's logo"
                    loading="lazy" class="logo__image" style="height:4rem;">
                </figure>
              </div>
            </a>
          </div>
  
        </div>
        <div class="tw-break-all" style="text-align:center;">© 2023 SIP Ltd.
          All Rights Reserved.
        </div>
        <!-- <ul class="c-social-links__links-container">
          <li>
            <a href="mailto:tourpioneer-service@outlook.com"
              target="_blank" class="tw-leading-none c-social-links__icon-container">
              <div is-fill-current="true" alt="icon" style="fill:#0e258c;"><svg
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" role="presentation"
                  class="c-icon c-icon--md">
  
                  <path
                    d="M14.125 3H1.875C1.392 3 1 3.3193 1 3.7143v8.5714c0 .395.392.7143.875.7143h12.25c.483 0 .875-.3193.875-.7143V3.7143C15 3.3193 14.608 3 14.125 3zm-.875 8.5714H2.75V7.088l4.816 2.2464c.2695.1257.5994.1257.868 0l4.816-2.2464v4.4835zm0-6.1285L8 7.892 2.75 5.443V4.4286h10.5v1.0143z"
                    fill-rule="nonzero"></path>
                </svg></div>
            </a>
          </li>
        </ul> -->
      </div>
  
    </div>
</template>

<script setup>
import { onMounted } from 'vue';
onMounted(()=>{
})
</script>

<style lang="scss" scoped>
</style>