<template>
  <div class="service-box">
    <a href="mailto:tourpioneer-service@outlook.com">
      <img src="@/assets/images/email.png"/>
    </a>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.service-box{
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10;
  cursor: pointer;
  img{
    width: 120px;
  }
}
</style>